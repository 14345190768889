import React, { Component, useState, useEffect } from "react";
import ReactModal from "react-modal";
import CavalierForm from "../Forms/CavalierForm";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./inscriptions.scoped.scss";
import central_rounded_logo from "../../assets/logo_round_white.png";
import { useCookies } from "react-cookie";

import Card from "../../components/Cards/eventCard"; //"./Components/CardsMouseFollow/Card";

function Inscriptions() {
	const [cookies, setCookie] = useCookies(["cookieConsent"]);
	const navigate = useNavigate();
	const [modalIsOpen, setIsOpen] = useState(false);
	const [data, setData] = useState([]);

	ReactModal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.55)";
	const customModalStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			backgroundColor: "white",
			border: "1px solid black",
			color: "black",
			width: "80%",
			height: "80%",
		},
	};
	function openModal() {
		setIsOpen(true);
	}

	function afterOpenModal() {
		// references are now sync'd and can be accessed.
	}

	function closeModal() {
		setIsOpen(false);
		navigate("/");
	}

    const giveCookieConsent = () => {
		setCookie("cookieConsent", true, { path: "/" });
	};

	useEffect(() => {
		axios
			// get using env variable:
			.get(process.env.REACT_APP_BACKEND_URL + "/events")
			.then((response) => {
				setData(response.data);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);
	return (
		<div className="global inscriptions">
			<img id="background" src={require("../../assets/handbookbg.png")} alt="" />

			<div className="divider" />
			<div className="flex-container">
				<div className="force-column">
					<img src={central_rounded_logo} alt="" />
				</div>

				{(cookies.cookieConsent && cookies.cookieConsent!="false") ? (
					<div className="nota">
						<div className="subcontainer">
							<div className="title">INSCRIPTIONS</div>
						</div>
					</div>
				) : (
					<div className="nota">
						<div className="subcontainer">
							<div className="title">INSCRIPTIONS</div>
							<span>Les cookies seront nécessaires pour certaines fonctionnalités à venir.</span>
							<span><span className="accept-cookies" onClick={giveCookieConsent}>Il vous est encore possible de les accepter en cliquant ici.</span></span>
						</div>
					</div>
				)}

				<div className="content">
					<div className="subcontainer">
						<div className="alert"><p> En vous inscrivant à une session au Yellow Blue Star, vous vous engagez à pratiquer le mountain trail selon la <Link to="/mountaintrail#philosophy"><b>philosophie du lieu</b></Link>.
Si notre approche ne vous convient pas, ne vous inscrivez pas. Le non-respect des consignes entraînera l'arrêt immédiat de la session. Aucun remboursement ne pourra être réclamé.</p></div>
						<ReactModal isOpen={modalIsOpen} contentLabel="Example Modal" style={customModalStyles}>
							<CavalierForm />
						</ReactModal>

					</div>
					<div className="wrapper">
						{data.map((item, index) => (
							<Card key={index} item={item} />
						))}
					</div>
					
				</div>
			</div>
		</div>
	);
}

export default Inscriptions;
