import React, { useState, useEffect, useRef } from "react";
import ReactModal from "react-modal";
import { Button } from "@mui/material";
import "./mountain.scoped.scss";
import bgwave from "../../assets/bg-wave.png";
import mountainMap from "../../assets/map/Maps.png";
import central_rounded_logo from "../../assets/logo_round_white.png";

import obs01 from "../../assets/map/01-Maze.png";
import obs02 from "../../assets/map/02-Woodpull.png";
import obs03 from "../../assets/map/03-Trench.png";
import obs04 from "../../assets/map/04-NarrowGate.png";
import obs05 from "../../assets/map/05-RopeGate.png";
import obs06 from "../../assets/map/06-Octopus.png";
import obs07 from "../../assets/map/07-Pinwheel.png";
import obs08 from "../../assets/map/08-FourLeaf.png";
import obs09 from "../../assets/map/09-LittleMaze.png";
import obs10 from "../../assets/map/10-AlterLog.png";
import obs11 from "../../assets/map/11-Lader.png";
import obs12 from "../../assets/map/12-CrossLog.png";
import obs13 from "../../assets/map/13-FourLog.png";
import obs14 from "../../assets/map/14-Oups.png";
import obs15 from "../../assets/map/15-BigLog.png";
import obs16 from "../../assets/map/16-Log.png";
import obs17 from "../../assets/map/17-ParallelLog.png";
import obs18 from "../../assets/map/18-Snake.png";
import obs19 from "../../assets/map/19-SidePass.png";
import obs20 from "../../assets/map/20-LittleMountain.png";
import obs21 from "../../assets/map/21-RaisedBack.png";
import obs22 from "../../assets/map/22-TurnBarrel.png";
import obs23 from "../../assets/map/23-WaterPond.png";
import obs24 from "../../assets/map/24-TireMound.png";
import obs25 from "../../assets/map/25-DragonTail.png";
import obs26 from "../../assets/map/26-DressLog.png";
import obs27 from "../../assets/map/27-Up&Down.png";
import obs28 from "../../assets/map/28-Up&Down.png";
import obs29 from "../../assets/map/29-TrenchLog.png";
import obs30 from "../../assets/map/01-Maze.png";
import obs31 from "../../assets/map/31-TwoLines.png";
import obs32 from "../../assets/map/32-LittleBox1.png";
import obs33 from "../../assets/map/01-Maze.png";
import obs34 from "../../assets/map/34-Little Mound.png";
import obs35 from "../../assets/map/35-WaterBlackHole.png";
import obs36 from "../../assets/map/01-Maze.png";
import obs37 from "../../assets/map/37-BigBox.png";
import obs38 from "../../assets/map/38-SquareSidePass.png";
import obs39 from "../../assets/map/39-LittleBox2.png";
import obs40 from "../../assets/map/40-BigMound.png";
import obs41 from "../../assets/map/01-Maze.png";
import obs42 from "../../assets/map/01-Maze.png";

function MountainTrail() {
	const [modalIsOpen, setIsOpen] = useState(false);
	const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
	const [overCoords, setOverCoords] = useState({ x: 0, y: 0, show: false, id: 0, image: "", name: "" });

	const appElement = document.getElementById("root");
	ReactModal.setAppElement(appElement);

	ReactModal.defaultStyles.overlay.backgroundColor = "#00000055";
	ReactModal.defaultStyles.overlay.display = "flex";
	ReactModal.defaultStyles.overlay.alignItems = "center";
	ReactModal.defaultStyles.overlay.justifyContent = "center";

	const customModalStyles = {
		content: {
			backgroundColor: "transparent",
			border: "none",
			color: "black",
			padding: "0px",
			inset: "revert",
			width: "30%",
		},
	};

	let coords = [
		{ x: 376, y: 258, image: obs01, name: "Maze" },
		{ x: 494, y: 213, image: obs02, name: "Woodpull" },
		{ x: 516, y: 304, image: obs03, name: "Trench" },
		{ x: 424, y: 328, image: obs04, name: "Narrow Gate" },
		{ x: 423, y: 356, image: obs05, name: "Rope Gate" },
		{ x: 359, y: 459, image: obs06, name: "Octopus" },
		{ x: 253, y: 474, image: obs07, name: "Pinwheel" },
		{ x: 332, y: 379, image: obs08, name: "Four Leaf" },
		{ x: 217, y: 412, image: obs09, name: "Little Maze" },
		{ x: 208, y: 543, image: obs10, name: "Alter Log" },
		{ x: 267, y: 563, image: obs11, name: "Lader" },
		{ x: 374, y: 523, image: obs12, name: "Cross Log" },
		{ x: 221, y: 628, image: obs13, name: "Four Log" },
		{ x: 410, y: 600, image: obs14, name: "Oups" },
		{ x: 431, y: 643, image: obs15, name: "Big Log" },
		{ x: 382, y: 655, image: obs16, name: "Log" },
		{ x: 326, y: 629, image: obs17, name: "Parallel Log" },
		{ x: 315, y: 665, image: obs18, name: "Snake" },
		{ x: 599, y: 653, image: obs19, name: "Side Pass" },
		{ x: 670, y: 616, image: obs20, name: "Little Mountain" },
		{ x: 755, y: 660, image: obs21, name: "Raised Back" },
		{ x: 822, y: 621, image: obs22, name: "Turn Barrel" },
		{ x: 759, y: 433, image: obs23, name: "Water Pond" },
		{ x: 797, y: 418, image: obs24, name: "Tire Mound" },
		{ x: 583, y: 369, image: obs25, name: "Dragon Tail" },
		{ x: 597, y: 330, image: obs26, name: "Dress Log" },
		{ x: 490, y: 516, image: obs27, name: "Up & Down" },
		{ x: 495, y: 555, image: obs28, name: "Up & Down" },
		{ x: 618, y: 230, image: obs29, name: "Trench Log" },
		{ x: 623, y: 170, image: obs30, name: "Maze" },
		{ x: 745, y: 253, image: obs31, name: "Two Lines" },
		{ x: 754, y: 310, image: obs32, name: "Little Box" },
		{ x: 222, y: 369, image: obs33, name: "Maze" },
		{ x: 445, y: 268, image: obs34, name: "Little Mound" },
		{ x: 768, y: 343, image: obs35, name: "Water Black Hole" },
		{ x: 651, y: 664, image: obs36, name: "Maze" },
		{ x: 297, y: 526, image: obs37, name: "Big Box" },
		{ x: 643, y: 291, image: obs38, name: "Square Side Pass" },
		{ x: 353, y: 597, image: obs39, name: "Little Box 2" },
		{ x: 474, y: 338, image: obs40, name: "Big Mound" },
		{ x: 489, y: 443, image: obs41, name: "Maze" },
		{ x: 684, y: 223, image: obs42, name: "Maze" },
        //{ x: 158, y: 373, image: obs41, name: "Stair" },
	];

	function openModal() {
		setIsOpen(true);
	}

	function afterOpenModal() {
		// references are now sync'd and can be accessed.
	}

	function closeModal() {
		setIsOpen(false);
	}

	function handleMouseClick(event) {
		if (overCoords.show) {
			openModal();
		}
	}

	function showObstacle(id) {
		let center = coords[id - 1];
		setOverCoords({ x: center.x, y: center.y, show: true, id: id, image: center.image, name: center.name });
		setIsOpen(true);
	}

	const philosophyTarget = useRef(null);

	React.useEffect(() => {
		const radius = 20;
		var c = document.getElementById("canvas");
		var ctx = c.getContext("2d");

		if(window.location.href.includes("philosophy")) {
			philosophyTarget.current.scrollIntoView({ behavior: "smooth" });
		}

		var img = new Image();
		img.src = mountainMap; // 3000*2249
		img.onload = function() {
			ctx.drawImage(img, 0, 0, c.width, c.height);
		};

		ctx.font = "30px Arial";
		function handleMouseMove(event) {
			let rect = c.getBoundingClientRect();
			let x = event.clientX - rect.left;
			let y = event.clientY - rect.top;
			let rx = c.offsetWidth / c.width;
			let ry = c.offsetHeight / c.height;
			setMousePos({ x, y });

			// Calculate the distance between the mouse position and the center coordinate

			setOverCoords({ x: 0, y: 0, show: false, id: 0 });
			for (let i = 0; i < coords.length; i++) {
				let center = coords[i];
				let dx = x - center.x * rx;
				let dy = y - center.y * ry;
				let distance = Math.sqrt(dx * dx + dy * dy);

				// Check if the distance is less than or equal to the radius
				if (distance <= radius) {
					setOverCoords({ x: center.x, y: center.y, show: true, id: i + 1, image: center.image, name: center.name });
					break;
				}
			}
		}

		function drawFrame() {
			ctx.clearRect(0, 0, c.width, c.height);
			ctx.drawImage(img, 0, 0, c.width, c.height); // draw the image
			if (overCoords.show) {
				ctx.shadowColor = "rgba(0, 255, 0, 0.9)";
				ctx.shadowOffsetX = 0;
				ctx.shadowOffsetY = 0;
				ctx.shadowBlur = 10;
				ctx.fillStyle = "rgba(255,255,255,1.0)";
				ctx.fillText(`${overCoords.id} - ${overCoords.name}`, 90, 160);
				ctx.shadowColor = "transparent";
				ctx.shadowBlur = 0;
				ctx.beginPath();
				ctx.arc(overCoords.x, overCoords.y, radius, 0, 2 * Math.PI);
				ctx.fillStyle = "rgba(255,0,0,0.5)";
				ctx.fill();
			}
			requestAnimationFrame(drawFrame);
		}

		requestAnimationFrame(drawFrame);

		c.addEventListener("mousemove", handleMouseMove);
		return () => {
			c.removeEventListener("mousemove", handleMouseMove);
			cancelAnimationFrame(drawFrame);
		};
	}, [mousePos, overCoords]);

	return (
		<div className="global mountaintrail">
			<img id="background" src={require("../../assets/handbookbg.png")} alt="" />

			<div className="divider" />
			<div className="flex-container">
				<div className="force-column">
					<img src={central_rounded_logo} alt="" />
				</div>

				<div className="title">LE MOUNTAIN TRAIL</div>
				<div className="preface">
					<p>
						Le Mountain Trail est une discipline équestre qui met l'accent sur la confiance, la communication et le partenariat entre le cheval et
						son cavalier. Il offre un parcours composé d'obstacles naturels ou artificiels qui imitent les défis rencontrés sur les sentiers en
						montagne, mettant ainsi les compétences des cavaliers et des chevaux à l'épreuve.
					</p>{" "}
					<p>
						Les obstacles peuvent inclure des ponts, des troncs d'arbres, des rochers, des poteaux, des montées et des descentes abruptes, ainsi que
						des passages étroits. Le Mountain Trail est une expérience équestre unique qui renforce la confiance et la communication entre le
						cavalier et son cheval, en les mettant au défi de naviguer dans des terrains difficiles.
					</p>
				</div>
				<div className="title">Venez découvrir le Sun Park</div>
				<div className="preface">
					<p>
						Le Sun Park est un lieu où vous et votre cheval pourrez passer un agréable moment, en toute sécurité. Une carrière de 1500m² ouverte sur
						plus d'une quarantaine de dispositifs qui vous permettront de développer une relation de confiance avec votre cheval, faire preuve de
						leadership et de communication pour guider votre équidés en toute sécurité à travers les différents obstacles.
					</p>
					<p>Tous les niveaux de compétences sont les bienvenus, peu importe l'âge ou le type d'équidé que vous possédez.</p>
				</div>
				<div className="title">Déroulé de votre session</div>
				<div className="preface">
					<p>
					Le parking ouvre 15 min avant le début de votre session. Si c est votre première venue, nous vous présentons la discipline et
					nous vous guidons sur les différents dispositifs et difficultés qu’ils peuvent représentés. Nous partageons avec vous nos valeurs et notre approche.
					Si vous etes deja venu et que vous êtes à l’aise avec nos regles de bonnes pratiques, vous pourrez evoluer librement sur le parcours. 
					</p>
					<p>
						Dès la fin de votre session, nous vous demandons de ramasser vos crottins sur le parcours de Mountain Trail, et de laisser le lieux
						aussi propre qu'à votre arrivée (foin et crottin autour du van doivent etre ramassés également)
					</p>
					<p>
					<p>Vous avez le choix entre deux formules : la séance ou la journée </p>
					<p>
					<p>La séance
					<li> Durée : 1h45</li>
					<li> Tarif : 33€ TTC par couple cavalier / cheval & 11€ TTC par accompagnant (maximum de 3)</li>
					<li> Ouverture du parking : 15 minutes avant le début de votre session</li></p></p>
					<p>La journée 
					<li> Durée : 9h30-12h00 / 13h00-16h00</li>
					<li> Tarif : 84€ TTC par couple cavalier/cheval & 20€ TTC par accompagnant (maximum de 3)</li>
					<li> Ouverture du parking : 15 minutes avant le début de votre session</li>
					<li> Déjeuner : Tirer du sac</li></p>
					</p>
					<p >
						A partir de 3 couples cavalier/cheval, vous pouvez privatiser le lieu. Vous me donnez la date souhaitée et si celle ci est possible,
						nous ouvrons une session pour vous et vos amis. Vous recevrez un code qui vous premettra d'accéder à votre session privée et de vous
						inscrire directement sur le site. Nous pouvons accueillir un maximum de 5 vans 2 plans ou VL sur notre parking.
					</p>
					<p ref={philosophyTarget}>
						Si vous ne souhaitez pas privatiser, vous pouvez soit vous inscrire à une session déja ouverte et libre d'accès, soit nous contacter
						pour en créer une. Dans ce cas, n'importe qui d'autre pourra s'inscrire dans la limite de 4 couples cavalier / cheval.
					</p>
				</div>
				<div className="title" >Les règles de bonnes pratiques</div>
				<div className="preface">
					<p>Le mountain trail est une discipline qui peut être dangereuse si certaines règles ne sont appliquées.</p>
					<p>Tous nos dispositifs sont conçus pour vous assurer un maximum de sécurité.</p>
					<p>Cependant, cela ne suffit pas. Vous devez également aborder les dispositifs d’une manière sécurisée, pour vous et pour votre cheval.</p>
					<p><li>Tout nouvel obstacle se doit d’etre abordé à pied. Une fois votre cheval à l’aise, vous pourrez le faire monté.</li>


					<li>Nous guidons les pieds de nos chevaux, jamais leur tête.</li>
					<li>Nous laissons notre cheval franchir seul le dispositif. Nous ne passons jamais devant lui. Nous restons toujours hors du dispositif, sur le coté et nous invitons notre cheval à le franchir dans le calme.</li>
					<li>Nous nous efforçons de maintenir l'état émotionnel de notre cheval aussi bas que possible </li>
					<li>Afin de rendre le plus agréable possible nos demandes, le cheval est autorisé à brouter sur un dispositif si cela peut l’aider à s’y sentir bien.</li>
					<li>Au cous de la session, plusieurs temps de broutage est accordé au cheval. </li></p>

					<p>
						Si vous n’êtes pas familier avec cette manière de faire, nous sommes la pour vous montrer avec nos chevaux, ou bien avec le votre si vous préférez.
						Vous pourrez évoluer librement sur le parcours à partir de votre seconde visite si vous êtes à l’aise avec nos règles de bonnes pratiques. 
					</p>
				</div>

				<div className="title">Matériel</div>
				<div className="preface">
					<p>Avoir le matériel adéquat est un élément essentiel à la sécurité de chacun et nous assure de bien pouvoir communiquer avec notre cheval.</p>
					<p>Vous devez donc venir, obligatoirement, avec le matériel suivant :

					<li>Un stick de dressage (à minima), un carrot stick étant conseillé</li>
					<li>Une longe de 3,70m minimum</li>
					<li>Un licol corde (le licol plat ne sera pas accepté)</li></p>
					<p>Chacun est libre de pratiquer l'équitation comme il le souhaite, dans son intimité.</p>
					<p>Cependant, en vous inscrivant à une session au Yellow Blue Star, vous vous engagez à pratiquer le mountain trail selon la philosophie du lieu.</p>
					<p>Si notre approche ne vous convient pas, ne vous inscrivez pas.</p>
					<p>Le non-respect des consignes entraînera l'arrêt immédiat de la session.
						Aucun remboursement ne pourra être réclamé.Le mountain trail est une discipline qui peut être dangereuse si certaines règles ne sont appliquées.</p>
				</div>

				<div className="title">Les Obstacles</div>
				<div className="obstacles">
                <center>Les obstacles peuvent varier au fil du temps.</center>
                <center>De nouveaux obstacles sont disponibles : la bascule, le pont large et le pont étroit.</center>
                <div className="wrapper">
						<div className="column">
                        <h2>Obstacles de 1 à 14</h2>
							<p />
							<p onClick={() => showObstacle(1)}>1- Maze</p>
							<p onClick={() => showObstacle(2)}>2- Woodpull</p>
							<p onClick={() => showObstacle(3)}>3- Trench</p>
							<p onClick={() => showObstacle(4)}>4- Narrow Gate</p>
							<p onClick={() => showObstacle(5)}>5- Rope gate</p>
							<p onClick={() => showObstacle(6)}>6- Octopus</p>
							<p onClick={() => showObstacle(7)}>7- Pinwheel</p>
							<p onClick={() => showObstacle(8)}>8- Four leaf</p>
							<p onClick={() => showObstacle(9)}>9- Little maze</p>
							<p onClick={() => showObstacle(10)}>10- Alter Log</p>
							<p onClick={() => showObstacle(11)}>11- Ladder</p>
							<p onClick={() => showObstacle(12)}>12- Cross Log</p>
							<p onClick={() => showObstacle(13)}>13- Four Log</p>
							<p onClick={() => showObstacle(14)}>14- Oups</p>
						</div>
						<div className="column">
							<h2>Obstacles de 15 à 29</h2>
							<p />
							<p onClick={() => showObstacle(15)}>15- Big Log</p>
							<p onClick={() => showObstacle(16)}>16- Log</p>
							<p onClick={() => showObstacle(17)}>17- Parallel Log</p>
							<p onClick={() => showObstacle(18)}>18- Snake</p>
							<p onClick={() => showObstacle(19)}>19- Side Pass</p>
							<p onClick={() => showObstacle(20)}>20- Little Mountain</p>
							<p onClick={() => showObstacle(21)}>21- Raised Back</p>
							<p onClick={() => showObstacle(22)}>22- Turn Barrel</p>
							<p onClick={() => showObstacle(23)}>23- Water Pond</p>
							<p onClick={() => showObstacle(24)}>24- Tire mound</p>
							<p onClick={() => showObstacle(25)}>25- Dragon Tail</p>
							<p onClick={() => showObstacle(26)}>26- Dress Log</p>
							<p onClick={() => showObstacle(27)}>27- Up and Down 1 - Arena</p>
							<p onClick={() => showObstacle(28)}>28- Up and Down 2 - Trench Log</p>
							<p onClick={() => showObstacle(29)}>29- Trench Log</p>{" "}
						</div>
						<div className="column">
							<h2>Obstacles de 30 à 41</h2>
							<p />
							<p onClick={() => showObstacle(30)}>30- Big Mountain</p>
							<p onClick={() => showObstacle(31)}>31- Two Lines</p>
							<p onClick={() => showObstacle(32)}>32- Little Box 1</p>
							<p onClick={() => showObstacle(33)}>33- Crescent moon</p>
							<p onClick={() => showObstacle(34)}>34- Little Mound</p>
							<p onClick={() => showObstacle(35)}>35- Water Black Hole</p>
							<p onClick={() => showObstacle(36)}>36- Half mountain</p>
							<p onClick={() => showObstacle(37)}>37- Big Box 360</p>
							<p onClick={() => showObstacle(38)}>38- Square side pass</p>
							<p onClick={() => showObstacle(39)}>39- Little Box 2</p> <p>40- Big Mount</p>
							<p onClick={() => showObstacle(42)}>41- Gravel road</p>
						</div>
					</div>
				</div>

				<div className="content">
					<ReactModal isOpen={modalIsOpen} contentLabel="Example Modal" style={customModalStyles} onRequestClose={closeModal}>
						<img style={{ width: "100%", borderRadius: "20px" }} src={overCoords.image} alt="Mountain Trail Map" />
					</ReactModal>

					<canvas id="canvas" width="1000" height="750" onClick={handleMouseClick} />
				</div>
				<div style={{ height: "20px", width: "100vw" }} />
			</div>
		</div>
	);
}

export default MountainTrail;
